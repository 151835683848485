import React, { useState } from 'react';
import "./MobileOfferCard.scss";
import Slider from 'react-slick';
import left from "../../../assets/left.png";
import next from "../../../assets/next.png";
import CustomNextArrow from "../../../library/baaShared/CustomArrow/CustomNextArrow";
import CustomPrevArrow from "../../../library/baaShared/CustomArrow/CustomPrevArrow";
import dic from "i18next";
import { isHomePage } from '../../../constants';



const MobileOfferCard = (props) => {
    const [isreadmoreClicked, setIsreadmoreClicked] = useState(false);
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow img={next} />,
        prevArrow: <CustomPrevArrow img={left} />,
    };

    const toggleReadMore = () => {
        setIsreadmoreClicked(!isreadmoreClicked);
    }


     return (
        <div className='mobile-offer-card-wrapper'>
            <div className='offer-card-title'>{dic.t("Jclub-Life Lived Well")}</div>
            <Slider {...settings}>
                {
                    props && props.contentlist && props.contentlist.map((item, index) => {
                        const srcimagecontent = props.contentlist[index].fields["Background Images"] && props.contentlist[index].fields["Background Images"][0];
                        const srcimage =  srcimagecontent &&  srcimagecontent.fields["Background Image"] && srcimagecontent.fields["Background Image"].value && srcimagecontent.fields["Background Image"].value.src;
                        const name = props.contentlist[index].fields && props.contentlist[index].fields.Title.value;
                        let findoutstatus = props.contentlist[index].fields["Show Find Out CTA"].value;

                        return (
                            <div className="offer-card-main-wrapper" key={index}>
                                <div className='offer-card-image'>
                                    <img src={srcimage} alt="dine" className='card-image' />
                                </div>
                                <div className='offer-card-content' id='card-content'  >
                                    <div className='offer-inner-card'>
                                        <div className='offer-title'>{name}</div>
                                        <div className='offer-description'   >
                                            <div className={`${!isreadmoreClicked ? 'readmore' : 'readmore expand'} `}>
                                                <div className='description'>
                                                <div dangerouslySetInnerHTML={{__html: props.contentlist && props.contentlist[index].fields && props.contentlist[index].fields.Description.value}}></div>
                                                 </div>
                                                
                                                 {
                                                    findoutstatus ?  
                                                        <div className='findout-more pb-2 pt-1'>
                                                                <a href={props.contentlist[index].fields["Find Out CTA"].value.href} target="_blank" rel="noopener noreferrer" className="text-reset text-decoration-none" ><button className="btn">Find Out More</button></a> 
                                                        </div> 
                                                    : ""
                                                }
                                                 {isHomePage() ?
                                                 <div className={`${!isreadmoreClicked ? 'readmore-links' : 'readmore-links expands'} `} onClick={toggleReadMore}></div>
                                                 :
                                                 <a href={props.contentlist[index].fields["Read More"].value.href} className='readmore-links'></a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    );
}

export default MobileOfferCard;