import React, { useState, useRef } from 'react';
import "./FacilitiesMobile.scss";
import Slider from 'react-slick';
import left from "../../assets/left.png";
import CustomNextArrow from "../../library/baaShared/CustomArrow/CustomNextArrow";
import CustomPrevArrow from "../../library/baaShared/CustomArrow/CustomPrevArrow";



const FacilitiesMobile = (props) => {

    const [isreadmoreClicked, setIsreadmoreClicked] = useState(false);
    const ref = useRef(null)

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow img={left} />,
        prevArrow: <CustomPrevArrow img={left} />,
    };

    const toggleReadMore = () => {
        setIsreadmoreClicked(!isreadmoreClicked);
    }
console.log(props,"PROPS")
        return (
            <div className='mobile-offer-card-wrapper'>
                <div className='offer-card-title'>{props.Title}</div>
                      <div className="offer-card-main-wrapper">
                        <div className='offer-card-image'>
                             <Slider {...settings}>
                                {
                                    props && props.Images && props.Images.map((item,index)=>{
                                        const srcimage = item.fields["Background Image"] && item.fields["Background Image"].value.src;
                                        return <img src={srcimage}  className="img-fluid image-bg" alt="facilities" key={index} />
                                    })
                                }
                            </Slider>

                        </div>

                        <div className='offer-card-content' id='card-content'>
                            <div className='offer-inner-card'>
                                <div className='offer-title'>{props.Title}</div>
                                <div className='offer-description'>
                                    <div className={`${!isreadmoreClicked ? 'readmore' : 'readmore expand'} `}>
                                        <div className='description' ref={ref} ><p dangerouslySetInnerHTML={{ __html: props.Description }}></p></div>
                                        <div className={`${!isreadmoreClicked ? 'readmore-link' : 'readmore-link expand'} `} onClick={toggleReadMore}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
            </div>
        );
    }

export default FacilitiesMobile;