import React from "react";
import "./offerDetails.scss"
const OfferDetails = (props) => {
    const contentData = props && props.fields && props.fields["Offer"] && props.fields["Offer"][0] && props.fields["Offer"][0].fields;
    const description = contentData && contentData.Description;
    const title = contentData && contentData.Title;
    const backgroundImages = contentData && contentData["Background Images"];

    // Extract background image safely
    const backgroundImage =
        backgroundImages &&
        backgroundImages[0] &&
        backgroundImages[0].fields &&
        backgroundImages[0].fields["Background Image"] &&
        backgroundImages[0].fields["Background Image"].value &&
        backgroundImages[0].fields["Background Image"].value.src;
    return (
        <React.Fragment>
            <div className="offer-detail-main-container">
                <div className="row p-0 m-0">
                    {/* Left Section with Background Image */}
                    <div
                        className="col-md-6 left-background-image"
                        style={{ backgroundImage: `url(${backgroundImage})` }}
                    ></div>

                    {/* Right Section with Content */}
                    <div className="col-md-6 col-12 right-background-event">
                        {/* Title */}
                        <div className="event-title">
                            <p>{title && title.value}</p>
                        </div>

                        {/* Long Description */}
                        <div className="event-description">
                            <div
                            style={{textAlign:'justify'}}
                                dangerouslySetInnerHTML={{
                                    __html: (description && description.value) || "",
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default OfferDetails;
